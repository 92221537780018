<template>
    <div>
        <div class="main-content">
            <div class="content">
                <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px" height="1%">
                    <el-table-column prop="accept_user" label="取件人" align="center" width="200"></el-table-column>
                    <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
                    <el-table-column prop="courier_name" label="所在小邮局" align="center"></el-table-column>
                    <el-table-column prop="mail_no" label="快递单号" align="center"></el-table-column>
                    <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
                </el-table>
                <el-pagination class="pages-center"
                               :current-page="listPages.currentPageNum"
                               :page-size="listPages.eachPageNum"
                               layout="prev, pager, next, jumper"
                               :total="listPages.total"
                               @current-change="pageCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {data_manage_takeList} from '@/config/apis'

    export default {
        data() {
            return {
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    paging: '1',
                    pageSize: this.listPages.eachPageNum,
                    page: this.listPages.currentPageNum,
                }
                data_manage_takeList(params).then((res) => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                    if (this.listPages.total !== 0 && this.infoList.length === 0) {
                        this.listPages.currentPageNum--;
                        this.getList();
                    }
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-content {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        display: flex;
        flex-direction: column;
    }

    .tab {
        text-align: right;
    }

    .content {
        flex: 1;
        height: 1%;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }
</style>